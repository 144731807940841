// background: linear-gradient(to bottom left, cyan 50%, palegoldenrod 50%);
@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";
 // background-color:rgba(lighten($color2,15%),.4);
  // background-color: #fafafa;
  // rgb(193 212 251)   rgb(82 109 165)

.Home{@include sec-default();position:relative;z-index:0;
  br.brMob{display:none;}
  @include bp(nn){br.new1{display:none;}}

  @include bp(og){//padding-bottom:15px;
    br.br1{display:none;}
    br.new1{display:block;}
  }
  @include bp(oc){br.brMob,br.new1{display:none;}}

  // HAS TEMPS *****
  .homeLay{@include sec-default();position:relative;//overflow:hidden;
    p.headline{@include fonty2(23px);line-height:1.5em;margin-bottom:40px;font-weight:bold;}
    p.rotitle, p.rotitle2{position:absolute;@include fonty(80px);font-weight:bold;letter-spacing:10px;line-height:1em;color:$color2-lt2;width:105px;opacity:.5;}
    p.rotitle{@include rotate(90deg);right:0;margin:40px 0 0 0;} // may do this as a min-width.
    p.rotitle2{@include rotate(-90deg);left:0;bottom:35%;margin:0;} // may do this as a min-width.

    @include bp(tw){p.headline{font-size:21px;}}
    @include bp(tb){
      p.headline{font-size:20px;margin-bottom:25px;}
      p.rotitle2{bottom:29%;left:-10px;}
    }
    @include bp(br){
      p.headline{font-size:19px;}
      p.rotitle,p.rotitle2{font-size:70px;width:86px;}
      p.rotitle{top:7%;}
      p.rotitle2{bottom:24%;left:-5px;}
    }
    @include bp(og){p.rotitle,p.rotitle2{display:none;}}
  }
  .hlService{padding:80px 0 0;position:relative;z-index:0;min-height:600px;
    &::before{@include before();width:70.5%;background-color:$color2-lt2;opacity:.7;}
    &::after{@include after();left:0;height:50px;background-color:#fafafa;}
    p.rotitle{z-index:2;}

    .stackImg,.stackText{display:inline-block;position:relative;}
    .stackImg{width:60%;}
    .stackText{z-index:3;}

    .item1{
      .stackImg{float:left;z-index:2;}
      .stackText{width:37%;float:right;margin-right:-2%;margin-top:100px;margin-bottom:70px;}
    }
    .item2{
      .stackImg{float:right;margin-right:-6%;margin-top:-70px;}
      .stackText{width:33%;float:left;margin-left:5%;margin-top:100px;}
    }

    .btnRow{@include sec-default();margin:10px 0 0;}
    .imgMobile,.imageRow{display:none;}

    h4{@include fonty(20px);font-weight:800;letter-spacing:2px;margin-bottom:7px;}
    p{line-height:1.7em;}
    a{font-size:13px;}
    button{color:$black;}

    @include bp(fk){&::before{width:67%;}}

    @media (max-width:1215px){padding-top:60px;
      .item1 .stackText{margin-right:0;}
      .item2{
        .stackImg{margin-right:-1%;}
        .stackText{width:35%;margin-left:2%;margin-top:60px;}
      }      
    }
    @include bp(tb){
      .item1 .stackText{margin-top:80px;}
      .item2 .stackImg{margin-right:0;}
      h4{font-size:19px;}
      p{font-size:16px;}
    }
    @include bp(br){padding-top:40px;
      p.headline{@include center-block();text-align: center;}
      .medDef{max-width:700px;}
      .stackImg{width:53%;margin-left:-1%;}
      .item1 .stackText{width:46%;margin-top:60px;margin-right:-2%;margin-bottom:20px;}
      .item2 .stackImg{margin-top:60px;}
      .item2 .stackText{width:45%;margin-left:0;margin-top:0;}
      h4{font-size:18px;}
    }
    @include bp(og){padding-top:30px;
      &::before{width:100%;height:90%;}
      .medDef{width:90%;max-width:550px;}
      .serveItem, .item1 .stackText, .item2 .stackText{@include sec-default();margin:0 0 10px;}
      .stackImg{display:none;}
      .item1{margin-bottom:40px;}

      h4 br{display:none;}

      .imageRow{@include sec-default();text-align:center;padding:30px 5px 0;
        .item{display:inline-block;width:49%;margin:0 .5%;}
      }
    }
  }

  .hlCareer{@include sec-default();padding:130px 0; position:relative;z-index:0;background-color:#fafafa;
    .rotitle2.cust{bottom:25%;}
    .medDef{display:flex;align-items:center;justify-content:space-between;flex-direction:row-reverse;}
    .left{width:60%;margin-left:-5%;}
    .right{width:43%;margin-right:-3%;position:relative;z-index:1;
      &::after{@include after();
        width:80%;height:125%;right:-5%;bottom:-10%;
        border:2px solid $color2-lt;z-index:-2;
      }
    }

    .clInner{padding:30px 0;background-color:#fafafa;}
    h4{@include fonty(20px);font-weight:bold;}
    p{margin-bottom:20px;}
    a{font-size:14px;}

    @include bp(tx){.right{margin-right:-1%;}}
    @include bp(tw){
      .rotitle2{z-index:-1;}
      .medDef{width:95%;max-width:900px;}
      .left{width:55%;margin-left:-2%;}
      .right{margin-right:0;margin-top:60px;}
    }
    @include bp(br){padding:60px 0;
      .medDef{max-width:600px;flex-direction:column;}
      .left{width:100%;margin:0;}
      .right{width:90%;max-width:500px;margin:0;
        &::after{display:none;}
      }
      .clInner{padding-top:0;}
    }
    @include bp(og){padding:30px 0;
      h4{font-size:18px;margin-bottom:10px;}
    }
  }
}