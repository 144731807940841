// background: linear-gradient(to bottom left, cyan 50%, palegoldenrod 50%);
@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.Exterior{@include sec-default();
  @include bp(big){max-width:1900px;@include center-block();}

  .midExt{padding-bottom:80px;
    &::after{@include after();left:3%;width:97%;height:65%; background-color:$color1-lt2;}
    @include bp(tw){padding-bottom:60px;}
    @include bp(br){&::after{left:0;width:100%;}}
    @include bp(nn){padding-bottom:40px;}
    @include bp(og){padding-bottom:20px;}
  }

  .extSlideWrap{@include sec-default();position:relative;z-index:0; margin-bottom:20px;//max-height:800px;overflow:hidden;
    .slideText{position:absolute;width:50%;max-width:500px;margin:3% 0 0 3%;z-index:2;}
    p{color:$white;font-weight:bold;font-size:27px;text-shadow:0px 1px 2px rgba($black,.5);font-family:$heading;line-height:1.4em;
      a{color:lighten($color1,10%);}
    }

    .maxHeight{max-height:800px;overflow:hidden;}
    .dotNav{width:100%;align-items:center;justify-content:center;margin-top:-30px;position:relative;z-index:2;
      a{width:12px;height:12px;border-color:$white;}
    }

    @include bp(tl){
      .slideText{max-width:460px;margin:25px 0 0 25px;}
      p{font-size:23px;}
    }

    @include bp(tw){//max-height:500px;
      .maxHeight{max-height:500px;}
      .slideText{max-width:420px;margin:25px 0 0 25px;}
      p{font-size:21px;}
    }

    @include bp(tb){
      .slideText{max-width:350px;margin:15px 0 0 15px;}
      p{font-size:19px;} 
    }
    
    @include bp(br){
      .slideText{width:100%;margin:0;max-width:100%;text-align:center;padding:15px 0;
        &::before{@include before();background:radial-gradient(rgba($black,.25) 20%, transparent 70%);width:80%;left:10%;top:-7%;}
      }
      p{font-size:17px;max-width:500px;@include center-block();margin-bottom:10px;text-shadow:0px 1px 2px rgba($black,.8);}
      p a{color:$white;}

      .dotNav{margin-top:-25px;}
      .ssImage::before{@include before();background-color:rgba($black,.2);z-index:2;}
    }

    @include bp(oc){
      .slideText{position:relative;padding:0;
        &::before{display:none;}
        p{color:$color1;text-shadow:none;}
        p a{color:$color2;}
      }
    }
  }

  .scrollSec{@include sec-default();}

  .serveList{@include sec-default();padding:80px 0 90px;
    .flexRow{
      width:95%;float:none;margin:auto;
      display:flex;justify-content:space-between;align-items:center;
    }
    .row1{max-width:1400px;}
    .row2{max-width:1550px;margin-top:60px;}
  
    .imageCol{width:35%;}
    .col1, .col3{width:31%;}
    .col3 .tbox{max-width:305px;@include center-block();}
    .col5{width:29%;padding:0 30px;text-align:center;
      .tbox{max-width:400px;@include center-block();}
    }
    
    h4{font-size:19px;margin-bottom:5px;font-weight:bold;text-transform:uppercase;}
    p{margin-bottom:10px;}
    p:last-child{margin-bottom:0;}

    @media (max-width:1575px){
      .col1 .tbox{padding:0 20px}
    }
    @include bp(tw){padding:60px 0 70px;
      .col3 .tbox{padding-left:10px;}
      .col4, .col6{width:32%;}
      .col5{width:33%;}
      .col5{padding:0 20px;}

      h4{font-size:18px;}
      p{font-size:16px;}
    }
    @include bp(nn){padding:40px 0 30px;
      .imageCol{display:none;}
      .row1{max-width:750px;align-items:flex-start;justify-content:space-around; padding-bottom:30px;border-bottom:1px solid rgba($color2,.1);}
      .col1, .col3{width:unset;
        .tbox{padding:0 15px;max-width:100%;}
      }
      .row2{max-width:720px;margin-top:30px;}
      .col5{width:100%;padding:0;
        .tbox{max-width:100%;}
      }
    }
    @include bp(oc){padding:20px 0 15px;
      .row1{flex-direction:column;grid-row-gap:25px;padding-bottom:0;border-bottom:0;text-align:center;}
      .col1, .col3{width:100%;
        .tbox{padding:0;}
      }
      .row2{margin-top:20px;}
    }
  }
  .mobImageRow{display:none;padding-bottom:20px;text-align:center;
    img{width:32%; display:inline-block;vertical-align:top;margin:.5%;}
    @include bp(nn){display:block;}
    @include bp(oc){padding-bottom:0px;
      img{width:48%;}
      .img2{display:none;}
    }
  }
}