@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&family=Nanum+Pen+Script&family=Montserrat:wght@400;500;600;700;800&display=swap');
// family=Mulish:wght@300;400;500;600;800;900&

@import './assets/global';
@import './assets/variables';
@import './assets/mixins';

*, *::before, *::after {box-sizing:border-box;}
body{margin:0;}

html {line-height:1.6em;font-size:17px;color:$color2;font-family:$body;font-weight:normal;}
h1, h2, h3, h4, h5, p{margin:0 0 15px;font-family:$body;color:$color2;}
p{font-size:17px;font-weight:normal;}

a{text-decoration:none;
  &:hover{opacity:.8;text-decoration:none;@include ease(.3s);}
}
button{background:none;border:none;border-radius:0;color:inherit;font-weight:inherit;font-family:inherit;
  font-size:inherit;letter-spacing:inherit;
  &:hover{cursor:pointer;color:currentColor;}
}
img{@include center-block();}
ul {margin:0;padding-left:0;}
li{list-style-type:none;}




.mdtcWrap{@include sec-default();}//position:relative;}
.MainContent{@include sec-default();position:relative;z-index: 0;
  @media (min-width:990px) {min-height:55vh;}
  @media (min-width:1024px) {min-height:65vh;}
}

.ToTopBtn{right:10px !important;
  svg{margin-bottom:4px;}
  @include bp(tn) {right:5px !important;}
  // @include bp(oc) {bottom:45px !important;}
}