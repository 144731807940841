@import './variables.scss';
@import './mixins.scss';

h1, h2, h3, h4, h5, p{
  &.color1, span.color1{color:$color1;}
  &.color2, span.color2{color:$color2;}
}

.inside, .insideAlt, .medDef, .med2Def, .med3Def, .med4Def, .insideXl, .insideN, .insideNrw, .insideTw, .custWrap{@include center-block();width:90%;}
.inside, .insideAlt, .insideXl, .insideN{max-width:1700px;}
.insideXl{width:95%;}
.inside{width:85%;}
.insideAlt{width:80%;}
.insideTw{max-width:1200px;}
.medDef{max-width:1080px;}
.med2Def{max-width:940px;}
.med3Def{max-width:850px;}
.med4Def{max-width:650px;}
@media screen and (min-width:823px){.insideNrw{max-width:820px;}}
@include bp(tb){.medDef, .med2Def, .med3Def, .med4Def, .insideNrw, .insideTw, .custWrap{width:92%;}}



.fullRow,.kpGrid{@include sec-default();
  // .flexWrap{display:flex;align-items:center;justify-content:space-around;}
  .hlf,.hlf1,.hlf2,.col,.left,.right{display:inline-block;vertical-align:top;}
  .hlf1,.col1,.left{float:left;}//margin-left:-2%;}
  .hlf2,.col3,.right{float:right;}//margin-right:-2%;}//margin-top:4%;}

  // .equal{width:47%;}
  // .small{width:33%;}
  // .large{width:65%;}

  .grid5{width:5%;}
  .grid15{width:15%;}
  .grid20{width:20%;}
  .grid21{width:21%;}
  .grid22{width:22%;}
  .grid23{width:23%;}
  .grid24{width:24%;}
  .grid25{width:25%;}
  .grid26{width:26%;}
  .grid27{width:27%;}
  .grid28{width:28%;}
  .grid29{width:29%;}
  .grid30{width:30%;}
  .grid31{width:31%;}
  .grid32{width:32%;}
  .grid33{width:33%;}
  .grid34{width:34%;}
  .grid35{width:35%;}
  .grid36{width:36%;}
  .grid37{width:37%;}
  .grid38{width:38%;}
  .grid40{width:40%;}
  .grid42{width:42%;}
  .grid44{width:44%;}
  .grid45{width:45%;}
  .grid46{width:46%;}
  .grid47{width:47%;}
  .grid48{width:48%;}
  .grid49{width:49%;}
  .grid50{width:50%;}
  .grid51{width:51%;}
  .grid52{width:52%;}
  .grid53{width:53%;}
  .grid54{width:54%;}
  .grid55{width:55%;}
  .grid56{width:56%;}
  .grid57{width:57%;}
  .grid58{width:58%;}
  .grid59{width:59%;}
  .grid60{width:60%;}
  .grid62{width:62%;}
  .grid63{width:63%;}
  .grid64{width:64%;}
  .grid65{width:65%;}
  .grid66{width:66%;}
  .grid67{width:67%;}
  .grid68{width:68%;}
  .grid69{width:69%;}
  .grid70{width:70%;}
  .grid72{width:72%;}
  .grid73{width:73%;}
  .grid75{width:75%;}
  .grid80{width:80%;}


  // *** KPKP - DO WE WANT TO BE SO STRICT HERE ?
  @include bp(br){
    .medDef{max-width:680px;}
    .medDef .left, .medDef .right,&.tbStack .left, &.tbStack .right{width:100% !important;}
  }
}
.overLight{position:relative;z-index:0;
  &:after{@include after();height:50%;left:0;background-color:$f7;}
}

.ipIntro{margin:20px 0 50px;position:relative;z-index:1;
  h1{font-family:$heading;font-weight:600;font-size:28px;line-height:1.5em;
    span,a{letter-spacing:1px;font-weight:bold;}
  }
  h2{@include fonty(16px);line-height:1em;margin-bottom:2px;letter-spacing:4px;font-weight:600;}
  p,h3{color:$gray2;line-height:1.8em;font-size:17.5px;}

  .mobSkip{@include sec-default();margin:10px 0;
    a{padding:6px 25px;font-size:14px;}
  }
  
  @media (min-width:1024px){.mobSkip{display:none;}}

  @include bp(tw){
    h1{font-size:26px;}
    h2{font-size:15px;}
  }
  @include bp(tb){margin-bottom:30px;
    h1{font-size:24px;}
    p,h3{font-size:17px;}
  }
  @include bp(br){margin-bottom:15px;text-align:center;
    h1{font-size:22px;margin-bottom:5px;line-height:1.4em;}
    p, h3{font-size:16px;}
    p:last-child,h3:last-child{margin-bottom:0;}
  }
  @include bp(og){.mobSkip a{font-size:13px;}}
  @include bp(oc){
    h1{font-size:20px;}
    h2{font-size:14px;}
  }

  &.exterior{
    .right{margin-top:-1%;width:69%;margin-right:-9%;}
    p{font-size:17px;}

    @include bp(tx){
      h1{font-size:25px;}
      h2{letter-spacing:2px;}
      .left{width:37%;margin-left:2%;margin-top:22px;}
      .right{width:62%;margin-right:-2%; margin-top:0;}
      p{font-size:16px;}
    }

    @include bp(tb){margin-bottom:20px;
      .left{margin-left:0;margin-top:3.8%;}
      h1{font-size:24px;}
    }
    @include bp(br){margin-bottom:25px;
      .medDef{max-width:700px;}
      .left{margin-left:0;margin-top:0;}
      .right{margin-right:0;margin-top:15px;}
      h1{@include center-block();max-width:500px;font-size:22px;}
    }
    @include bp(oc){
      h1{font-size:20px;}
      h2{font-size:14px;}
    }
  }

  &.marine{
    .left{width:40%;margin-left:-2%;}
    .right{width:66%;margin-right:-6%;margin-top:-9px;}
    
    h1 span{letter-spacing:0;}
    @include bp(tx){
      .right{width:64%;margin-right:-5%;}
    }
    @include bp(tw){
      .medDef{max-width:900px;}
      .left{width:42%;margin-left:-1%;margin-top:20px;}
      .right{width:60%;margin-right:-3%;}

      h2{letter-spacing:2px;}
      h1{font-size:24px;}
      p{font-size:16px;}
    }
    @include bp(br){
      .medDef{max-width:800px;}
      .left{margin-top:0;margin-left:0;}
      .right{margin-top:20px;margin-right:0;}
      h1{font-size:22px;}
    }

    @include bp(og){
      h1{font-size:19px;}
    }
  }


  &.about{
    h1{font-size:27px;}
    a{letter-spacing:0;}
    p{font-size:17px;}
    
    .left{width:67%;margin-left:-3%;}
    .right{width:36%;margin-right:-4%;margin-top:24px;}

    @media (min-width:1201px){h2{font-size:18px;}}
    
    @include bp(tw){
      h1{font-size:24px;}
      .left{width:62%;margin-left:0;}
      .right{width:34%;margin-right:0;margin-top:0;}
    }
    @include bp(tb){
      h1{font-size:22px;}
    }
    @include bp(br){
      h1{font-size:20px;}
      h2{letter-spacing:1px;}
      .left, .right{margin:0 auto;max-width:100%;}
      .right{margin-top:20px;}
    }
    @include bp(og){h1{font-size:19px;}}
    @include bp(oc){h1{font-size:18px;}}
  }
}

.imgRow{@include sec-default();margin-bottom:70px;
  &,.irWrap,img{position:relative;z-index:0;}
  .irWrap{@include center-block();}
  .irWrap.medPlus{width:95%;max-width:1200px;}

  .overImg{position:absolute;z-index:1;width:38%;bottom:0;right:0;background-color:$f7;padding:20px 0 0 25px;}
  p{font-size:17px;font-weight:400;}
}

// TEXT COMPONENTS? displayed on marine and exterior
.midExt{position:relative;z-index:1;
  .insideN{margin-left:9%;}
  @include bp(tb){.insideN{margin-left:7%;}}
  @include bp(br){.insideN{margin-left:auto;}}
}
.overText{@include sec-default();position:relative;z-index:2;text-align:center;
  @include bp(br){p{font-size:16px;max-width:740px;@include center-block();}}
}

// 
.centerBlock{@include center-block();}
.bmargin0{margin-bottom:0;}
.bmargin05{margin-bottom:5px;}
.bmargin1{margin-bottom:10px;}
.tmargin0{margin-top:0;}
.tmargin2{margin-top:20px;}
.textCenter{text-align:center;}
.textLeft{text-align:left;}
.textRight{text-align:right;}
.tpadding4{padding-top:40px;}
.tpadding6{padding-top:60px;}

.spacer1,.spacer2,.spacer25,.spacer3,.spacer4,.spacer5,.spacer9{@include sec-default();}
.spacer1{height:10px;}
.spacer2{height:20px;}
.spacer25{height:25px;}
.spacer3{height:30px;}
.spacer4{height:40px;}
.spacer5{height:50px;}
.spacer9{height:90px;}

p.cat{text-transform:uppercase;font-weight:800;letter-spacing:5px;font-size:15px;margin-bottom:0px;
  @include bp(og){letter-spacing:2px;}
  @include bp(oc){letter-spacing:0;}
}
span.cat{text-transform:uppercase;font-weight:900;letter-spacing:3px;font-size:.85em;font-family:$heading;}

hr{border-color:rgba($black,.1);
	&.hlf,&.hlfC{width:50%;}
  &.hlfC, &.center{@include center-block();}
	&.less{width:25%;}
	&.l120{width:120px;}
}
.clearfix::after, .clearfixBtn::after,.clearfixBtn2::after {content:"";clear:both;display:table;}
.clearfix.btn, .clearfixBtn{@include sec-default(); margin-top:20px;}
.clearBorder, .clearBorderWhite, .clearBorderLeft{@include sec-default(); height:50px; position:relative; z-index:0; margin:0px 0 20px;
  &::before{@include before(); width:1px; left:49.7%; background-color:rgba($black,.2);}
}
.clearBorderWhite::before{background-color:rgba($white, .3);}
@include bp(br){
  .clearfix.brSpace1{margin-bottom:10px;}
  .clearfix.brSpace2{margin-bottom:20px;}
}
.visuallyHidden {@include visuallyHidden();}
.imgResponsive {max-width:100%; height:auto; display:block;}

.mdLink,.mdLink2,.mdLinkRev,.mdLink2Rev,.mdLinkSm,.mdLinkRed,.mdLinkXl,.regBtn{@include link($color1, $white); padding:8px 25px;
  box-shadow:0 10px 25px rgba($black,.15);letter-spacing:.6px;text-transform:uppercase;font-family:$heading;
  &.inline{margin:3px;}
  &.xl{width:100%;@include sec-default();}
  &:hover{color:$white !important;}
  // @include bp(tb) {font-size:15px;}
}
.mdLinkSm{padding:5px 20px 7px;}
.mdLink2{background-color:$color2;color:$white;text-shadow:none;border: 1px solid $color2;
  &:hover{color:$white !important;}
}
.mdLinkRev{background-color:$white;color:$color1;text-shadow:none;border:1px solid $color1;
  &:hover{color:$color1 !important;}
}
.mdLink2Rev{background-color:$white;color:$color2;text-shadow:none;border:1px solid $color2;
  &:hover{color:$color2 !important;}
}

.mdLinkRed{background-color:$red;}
.regClear{height:15px;}
@media (max-width:767px){
	.regBtn{font-size:14px;}
	.regClear{height:10px;}
}

.textLink{font-weight:600;color:$color1;
  &.und {text-decoration:underline;}
  &:hover{color:darken($color1,10%) !important; opacity:1;}
}

ul.disc li{list-style-type:disc;}
.dotList{
  li{display:inline-block;position:relative;z-index:0;padding:0 10px;}
  li::before{@include abs();width:4px;height:4px;top:46%;right:-2px;background-color:$black;border-radius:50%;}
}
