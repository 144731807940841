// background: linear-gradient(to bottom left, cyan 50%, palegoldenrod 50%);
@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.About{@include sec-default();
  .certText2{@include sec-default();text-align:center;position:relative;z-index:2; margin-bottom:30px;
    p{font-weight:bold;@include fonty(17px);letter-spacing:.5px;margin-bottom:10px;}
    span.dot{margin:0 8px;}
    br{display:none;}

    @include bp(tw){
      br{display:block;}
      span.dot{display:none;}
    }

    @include bp(tb){margin-bottom:20px;}
    @include bp(br){margin-bottom:0;}
    @include bp(og){p{font-size:16px;}}
  }

  .logoComboOG{@include sec-default();padding:40px 0 70px;
    .logo1,.logo2,.text{display:inline-block;}
    .logo1{width:22%;float:left;margin-top:33px;}
    .logo2{width:11%;float:right;margin-top:19px;}
    .text{width:58%;margin:0 4%;text-align:justify;}

    @include bp(tb){padding:15px 0 30px;
      .logo1{margin-left:0;width:20%;}
      .logo2{margin-right:0;margin-top:25px;}
    }
    @include bp(br){padding-top:8px;text-align:center;
      .text{@include sec-default();margin:0 auto 10px;text-align:center;}
      .logo1,.logo2{float:none;vertical-align:top;width:auto;text-align:center;}
      .logo1{margin:12px 15px 0 0;
        img{max-width:230px;}
      }
      .logo2{margin:-10px 15px 0 15px;
        img{max-width:120px;}
      }
    }
    @include bp(og){padding:0 0 20px; p{font-size:16px;}}

    @include bp(oc){padding-bottom:10px;
      .text{margin-bottom:0;}
      .logo1,.logo2{@include sec-default();margin:0 0 15px;}
    }
  }

  .abContent{background-color:$color1-lt2;padding-bottom:30px;}
  .logoCombo{@include sec-default();padding:50px 0 0px;margin-bottom:15px;position:relative; 
    .text{width:73%;@include center-block();}
    .ilLogo,.text{display:inline-block;}
    .logo1{width:25%;float:left;margin:22px 1.5% 0 -12%;
      img{max-width:240px;float:left;}
    }
    .logo2{width:16%;float:right;margin:10px -8% 0 0;
      img{max-width:118px;float:left;}
    }

    @media (max-width:1450px){text-align:center;
      .text{width:100%;max-width:1000px;display:block;text-align:center;}
      .logo1,.logo2{float:none;vertical-align:top;width:auto;text-align:center;vertical-align:top;
        img{float:none;}
      }
      .logo1{margin:20px 50px 10px -10.3%;
        img{max-width:230px;}
      }
      .logo2{margin:0px 0 10px 50px;}
    }
    @include bp(tw){
      .logo1{margin-right:25px;margin-left:0;
        img{max-width:230px;}
      }
      .logo2{margin-left:25px;}
    }
    @include bp(br){padding-top:0;
      .text{@include sec-default();}
      .logo1 img{max-width:210px;}
      .logo2 img{max-width:105px;}
    }

    @include bp(og){p{font-size:16px;}}

    @include bp(oc){
      .ilLogo{@include sec-default();margin:0 0 20px;}
    }
  }

  .abSlideWrap{@include sec-default();position:relative;padding:20px 0 10.5%;
    &::after{@include after();background-color:$color1-lt2;left:0;height:50%;}
    @media (max-width:1450px){padding-top:0;}
    @include bp(tw){padding-bottom:15%;}
    @include bp(br){padding-top:10px;padding-bottom:19%;}
    @include bp(tn){padding-bottom:20%;}
    @include bp(oc){padding-bottom:20px;}
  }
  .textInstr{@include sec-default();text-align:center;background-color:$color1-lt2;margin-top:-10px;
    p{font-size:15px;font-weight:600;}

    @media (max-width:1450px){margin-top:0px;padding-top:5px;}
    @include bp(tw){padding-top:15px;}
    @include bp(br){padding-top:0;margin-top:-26px;padding-bottom:20px;}
    @include bp(tn){margin-top:-15px;padding-bottom:10px;}
    @include bp(og){p{font-size:14px;}}
  }

  .abSlideshow{@include sec-default();position:relative;z-index:1;
    .slItems,.slItems>*{overflow: visible !important;}
    .slItems{width:36%;@include center-block();z-index:2;border-left:2px solid transparent;border-right:2px solid transparent;}

    .thumbWrap{width:100%;position:absolute;left:0;bottom:-5%;}
    .thumbNav{@include sec-default();margin:0;padding:0 2px;position:relative;z-index:1;}
    .thumbImg{@include grayscale();display:inline-block;padding:0 2px;width:16%;
      &.i0,&.i1{float:left;}
      &.i2,&.i3{float:right;}
      
      button{padding:0;}
      button::after{display:none;}
    }

    @media (max-width:1450px){
      .slItems{width:40%;}
      .thumbImg{width:15%;}
    }

    @include bp(tw){
      .slItems{width:60%;border:none;
        img{border:2px solid $white;}
      }
      .thumbWrap{bottom:-30%;}
      .thumbImg{width:50%;
        button{width:45%;margin-top:5px;}
      }
      .thumbImg.i1{float:right;}
      .thumbImg.i2{float:left;}
      .thumbImg.i0,.thumbImg.i2{button{float:left;}}
      .thumbImg.i1,.thumbImg.i3{button{float:right;}}
    }
    @include bp(tn){
      .slItems{width:65%;}
      .thumbWrap{bottom:-25%;}
    }

    @include bp(oc){
      .slItems{width:95%;}
      .thumbWrap{position:relative;margin-top:25%;}
      .thumbImg{width:25%;
        button{width:100%;}
      }
      .thumbNav{z-index:5;}
    }
  }

  .infoRow{padding:100px 0 130px;position:relative;z-index:0;
    .medDef{max-width:85%;}
    .irBody{@include sec-default();padding:100px 0;position:relative;
      &::before,&::after{@include abs();max-width:520px;width:50%;height:60%;background-color:$color2-lt2;}
      &::before{top:0;left:50%;margin-left:-30%;}
      &::after{bottom:0;right:50%;margin-right:-32%;}
    }

    .imgMobileSec{display:none;}
    .irImg{position:absolute;width:45%;max-width:500px;left:50%;top:60px;margin-left:-15%;
      .ir2{margin-top:10px;}
    }
    .stackLeft,.stackRight{display:inline-block;position:relative;z-index:0;
      &::before{@include after();width:60px;height:calc(100% + 40px);bottom:-20px;background-color:$color2-lt2;}
    }
    .stackLeft{width:60%;max-width:430px;margin-top:5%;float:left;
      &::before{right:-15px;}
    }
    .stackRight{width:40%;max-width:390px;margin-top:23%;float:right;
      &::before{left:-15px;}
    }

    .absText{text-align:right;
      a br{display:none;}
    }
  
    p{margin-bottom:25px;}
    p.sig{@include fonty(21px);font-weight:bold;letter-spacing:2px;margin-bottom:15px;}  
    .mdLink2,.mdLink2Rev{display:inline-block;letter-spacing:.3px;padding:10px 15px;font-size:14px;}
    .md1{margin-right:15px;}
    .md2solo{min-width:120px;}

    @include bp(big){padding-bottom:200px;
      .irBody{
        &::before,&::after{width:56%;height:62%;max-width:600px;}
        &::before{margin-left:-32%;}
        &::after{bottom:-10%;margin-right:-35%;}
      }

      .irImg{width:45%;max-width:650px;margin-left:-300px;}
      .stackRight{margin-top:27%;}
    }
    @media (min-width:1900px){.medDef{max-width:1615px;}}

    @media(max-width:1680px){.irImg{margin-left:-16%;}}
    @media(max-width:1600px){
      .irBody::after{bottom:-40px;}
      .irImg{margin-left:-18%;}
      .stackLeft{margin-left:-5%;}
      .stackRight{margin-right:-5%;}
    }

    @media(max-width:1450px){
      .irBody::before,.irBody::after{height:70%;max-width:520px;}
      .irBody::after{bottom:0px;}
      .irImg{max-width:450px;margin-left:-200px;}
    }

    @include bp(tl){padding-bottom:100px;
      .irBody::before,.irBody::after{height:70%;max-width:520px;}
      .irBody::after{bottom:0px;}
      .irImg{max-width:450px;margin-left:-200px;}
      .stackLeft{max-width:380px;}
      .stackRight{max-width:330px;}

      .absText{
        .md1{margin-left:-4%;}
        .md2{float:right;margin-right:-12%;}
      }
    }

    @media(max-width:1285px){padding-bottom:130px;
      .medDef{max-width:950px;}
      .irBody::before{display:none;}
      .irBody::after{height:90%;max-width:100%;width:60%;margin-right:0;right:20%;bottom:5%;z-index:-2;}

      .irImg{width:100%;max-width:100%;margin-left:0;left:0;z-index: -1;top:0;height:100%;
        img{width:50%;position:absolute;z-index:-1;}
        .ir1{right:-3%;top:-40px;}
        .ir2{left:-3%;bottom: -70px;}
      }

      .stackLeft,.stackRight{max-width:100%;
        &::before{display:none;}
      }
      .stackLeft{width:47%;margin-left:0;  width:50%;margin-left:-2%;  margin-top:0;}
      .stackRight{width:48%;margin-right:-1%;  width:51%;margin-right:-4%;  margin-top:22%;}

      .absText .md2{margin-right:0;}
      .mdLink2, .mdLink2Rev{padding:10px;}
    }

    @include bp(br){
      .medDef{max-width:750px;}
 
      .stackLeft,.stackRight{width:50%;}
      .stackLeft{margin-left:-20px;}
      .stackRight{margin-right:-10px;}

      .absText {
        .md1, .md2{display:block;margin:0 0 12px;float:right;}
        .md1{margin-bottom:12px;margin-left:5px;}
      }

      p{font-size:16px;}
      p.sig{font-size:19px;margin-bottom:10px;}
      .mdLink2,.mdLink2Rev{font-size:13px;}
    }

    @include bp(md){.irBody::after{width:75%;right:12.5%;}}

    @include bp(og){padding:30px 0;
      .medDef{max-width:600px;}
      .irBody{padding:0;}
      .irBody::after{display:none;}

      .irImg{display:none;}
      .imgMobileSec{@include sec-default();padding:30px 20px 0;position:relative;z-index:0;
        &::before{@include before();top:-185px;background-color:$color2-lt;opacity:.6;}
      }

      .stackLeft,.stackRight{width:100%;margin:0;z-index:1;text-align: center;}
      .stackLeft{margin:0 0 30px;}
      .absText{text-align:center;
        .md1,.md2{display:inline-block;float:none;vertical-align:top;margin:0 6px 12px;}
      }

      p.sig{font-size:18px;margin-bottom:0;letter-spacing:1px;}
    }

    @include bp(fi){padding:20px 0;
      .imgMobileSec::before{height:75%;top:15px;}

      .absText{
        .md1,.md2{width:100%;display:block;max-width:220px;margin:0 auto 12px;}
      }
    }
  }
}