@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.Marine{@include sec-default();
  @include bp(big){max-width:1900px;@include center-block();}

  .services{@include sec-default();padding:40px 0 80px;
    .item{width:49%;display:inline-block;}
    .combo{float:right;margin-top:60px;}
    .textBox{@include sec-default();padding:30px;margin-bottom:30px;background-color:#f8f8f8;text-align:center;}
    h4{font-size:20px;margin-bottom:5px;font-weight:bold;text-transform:uppercase;}
    img+img{margin-top:30px;}
    ul{display:flex;flex-wrap:wrap;justify-content:center; grid-column-gap:20px;grid-row-gap:5px;}
    li{font-size:18px;position:relative;z-index:0;
      &::before{@include abs();width:3px;height:3px;top:50%;margin-top:-1.5px;left:-12px; background-color:$black;border-radius:50%;}
      &:first-child::before{display:none;}
    }
    
    @include bp(nn){padding:10px 0 40px;
      .combo{margin-top:0;}
      .textBox{margin-bottom:15px;padding-left:20px;padding-right:20px;}
      img+img{margin-top:15px;}
    }

    @include bp(og){padding-bottom:20px;
      .item{width:100%;}
      .textBox{padding:15px;}
      .i2{margin-top:15px;}
      ul{max-width:500px;justify-self:center;}
      li{font-size:17px;}
      img{width:95%;max-width:500px;@include center-block();}
    }
  }
}