@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.ServiceText{
  &.stdSpan p span{font-weight:bold;font-family:$heading;}

  &.marineQt{
    span{color:$color1;}
    a{color:$color1b;font-weight:bold;}
  }
}