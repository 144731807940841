@import '../../assets/global';
@import '../../assets/variables';
@import '../../assets/mixins';

.Header{@include sec-default();text-align:center;font-family:$monte; position:relative;z-index:1;
  &.defaultStyle{margin-bottom:30px;}

  .topbar{display:none;background-color:$color1;padding:2px;font-size:14px;color:$white;
    .dot{margin:0 10px;}
    a{color:inherit;}
  }
  .topItem, .navItem{display:inline-block;vertical-align:top;}
  .topRow{@include sec-default();padding:15px 0;font-size:14px;font-weight:bold;}
  .topWrap{width:95%;position:relative;}
  .topItem{width:30%;}
  .topLink{margin-top:20px;}
  .tiEmail{float:left;text-align:left;}
  .tiPhone{float:right;text-align:right;}
  .logoLink{max-width:270px;@include center-block();}

  .navMm{@include sec-default();padding:10px 0;background-color:#fafafa;}
  .navBarCustom{justify-content:center;}

  .navItem{margin:0px 5px;padding:0 15px;position:relative;z-index:0;
    &::after{@include abs();width:5px;height:5px;right:-7px;top:41%;border-radius:50%;background-color:rgba($color1,.25);}
    &:last-child::after{display:none;}
    &:nth-child(2){margin-left:0;}
    &:last-child{margin-right:0;}
    &.homeLink{display:none;}

    &, a{font-size:16px;letter-spacing:.3px;font-weight:500;height:auto;min-height:auto;font-family:$monte;text-transform:uppercase;color:$color2;}
    a{padding:0;}
    &:hover > a,a:hover{color:$color2;opacity:.7;}
    .parentIcon{width:14px;}
  }
  .navItem.mob, .dropLi.mob{display:none;}
  .custDrop{text-align:left;width:210px;padding:0;z-index:999;margin-top:10px;background-color:$color1-lt;border-left:2px solid $color1;
    li{padding:7px 15px;}
  }

  .mobNav{display:none;position:absolute;width:30px;right:0;top:22%;}
  .mobileNavBtn{width:25px;padding:0;
    span{@include sec-default();height:2px;margin:2.5px 0;background-color:$color2;}
    p,.drawerText{display:none;}
  }

  @include bp(tb){&.defaultStyle{margin-bottom:15px;}}
  @media (max-width:989px){
    .topbar{@include sec-default();}
    // .topRow{padding:10px 0;}
    .topLink{display:none;}
    .logo{width:auto;max-width:200px;@include center-block();}
    .hdWrap{width:95%;}
    .navItem, .navItem a{font-size:15px;}
  }

  @include bp(og){
    &.defaultStyle{margin-bottom:0px;}
    .mobNav{display:block;}
    .topRow{padding-top:12px;}
    .topWrap{max-width:400px;}

    .navMm{display:none;}
  }
  @media (max-width:590px){
    .topbar{padding:4px;line-height:1.5em;word-wrap:break-word;
      .tbText{width:100%;display:block;}
      .d1{display:none;}
      .d2{margin:0 5px;}
    }
  }
  @media (max-width:372px){
    .topbar{padding:6px;
      .tbPhone, .tbEmail{width:100%;display:block;}
      .tbPhone{margin:2px 0;}
      .d2{display:none;}
    }
  }
}

.drawerRoot,.navDrawer{border:none;}
.SideNavList{padding:40px 15px 0;font-family:$monte;
  .SideNav{margin-top:10%;}
  .closeIcon {color:$white;position:absolute;right:10px;top:15px;cursor:pointer;font-weight:normal;
    svg{width:25px;height:25px;}
  }

  .navItem,.dropLi{@include sec-default();line-height:1em;margin:15px 0;
    &.mob{display:block;}
    &.fw{display:none;}

    &.withDrop{margin:0;
      .parentLink{display:none;}
    }

    a, button{font-size:17px;line-height:1em;padding:0;font-family:$monte;font-weight:400;color:$white;text-transform:none;
      &:hover{opacity:.7;color:$white;}
    }
  }
  .custDrop{@include sec-default();margin-top:0;background-color:transparent;padding:0;position:relative;box-shadow:none;}

  .sideContact{@include sec-default();border-top:1px solid rgba($white, .2);margin-top:30px;padding-top:30px;word-wrap:break-word;
    .scItem{@include sec-default();margin-bottom:15px;}
    p, a{color:$white;font-size:15px;}
    svg{width:18px;height:18px;}
  }

  @media (max-width:350px){
    .sideContact{
      p, a{font-size:15px;}
      svg{width:17px;height:17px;}
  }
  }
}
