$black:#000;
$black01:rgba($black,.1);
$black02:rgba($black,.2);
$white:#fff;
$white01:rgba($white,.1);
$red:#ed1e24;
$f7:#f7f7f7;
$fa:#fafafa;
$gray:#808080;
$gray2:#595959;
$color1:#df7c39;
$color1b:#DB6E23;
$color1-md:#F8E4D7;
$color1-lt:#fdf9f7;
$color1-lt2:#FCF6F3;
$color2:#13223f;
$color2-lt:#d5dbe8;
$color2-lt2:#eaedf3;
$color2l:lighten($color2,30%);
// $color2-lt2:#E5E9F1;



$border:1px solid rgba($black,.1);
// $mul:'Mulish', sans-serif;
$nan: 'Nanum Pen Script', cursive;
$nan0:'Nanum Gothic', sans-serif;
$monte:'Montserrat', sans-serif;

$body:$monte;
$emph:$nan;
$heading:$monte;