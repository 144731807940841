@import '../../assets/global';
@import '../../assets/variables';
@import '../../assets/mixins';

.Footer{@include sec-default();background-color:$color1;
  p,li,a,svg{color:$white;}

  .topFooter{@include sec-default();padding:50px 0 45px;position:relative;
    & *{font-weight:500;}
    p{font-size:15px;margin-bottom:0;}
  }
  .tfwRow{display:flex;justify-content:space-between;align-items:center;}

  .ftLogo{max-width:200px;margin-bottom:12px;display:block;}
  .col1{width:33%;}
  .col2{
    p{line-height:1.4em;}
    p.tmargin{margin:15px 0;}
    p.il{display:inline-block;vertical-align:top;}
    ul{vertical-align:top;margin-top:-6px;margin-left:25px;}
    li svg{width:18px;height:18px;}
  }
  .licenses br{display:none;}

  .col1 .ftLogo, .col2 .licenses{display:none;}

  .btmFooter{padding:12px 0 15px;background-color:$color2;text-align:center;
    p.copy{font-size:13px;margin:15px 0 0;font-weight:300;line-height:1.5em;
      br{display:none;}
    }
  }
  .ftMenu{
    .navItem, .dropLi{font-size:14px;padding:0 15px;display:inline-block;position:relative;z-index:0;
      &::after{@include abs();width:1px;height:15px;right:0;top:50%;margin-top:-7.5px;background:rgba($white,.25);}
      &.withDrop{padding:0;}
    }
    .navItem:first-child{padding-left:0;}
    .navItem:last-child{padding-right:0;
      &::after{display:none;}
    }
    .parentLink{display:none;}
  }
  
  .eoe{@include sec-default();background-color:$white;padding:25px 0;text-align:center;
    .insideN{max-width:1350px;}
    p{font-size:14px;color:$color2;line-height:1.3em;margin:0;}
  }

  @include bp(tw){
    .tfwRow{justify-content:space-evenly;}
    .topFooter{padding:40px 0;
      .footWrap{max-width:1000px;}
    }
    .col1{width:unset;max-width:470px;margin-left:-6%;}
    .col1 .ftLogo, .col2 .licenses{display:block;}
    .col3{display:none;}
    .licenses{width:100%;margin-top:10px;padding-top:14px;border-top:1px solid rgba($white,.3);
      p+p{margin-top:7px;}
    }
  }
  @include bp(br){
    .col1{margin-left:-3%;}
  }

  @include bp(nn){text-align:center;
    .topFooter{padding:20px 0;
      .footWrap{max-width:600px;}
    }
    .ftLogo{max-width:160px;@include center-block();}
    .tfwRow{flex-direction:column;}
    .col1{max-width:100%;margin-left:0;}
    .col2{margin-top:10px;padding-top:14px;border-top:1px solid rgba($white,.3);}
  }
  
  @include bp(og){
    .topFooter{padding:15px 0;
      p{font-size:14px;}
    }
    .col2 li svg{width:17px;height:17px;}
    .ftMenu{display:none;}
    .btmFooter{padding-bottom:12px;
      p.copy{margin-top:0;}
    }
  }

  @include bp(oc){margin-top:20px;}

  @media (max-width:350px){.licenses br{display:block;}}
  @media (max-width:280px){
    .col2{
      p.il{display:block;}
      ul{margin:10px 0 0;}
    }
  }
}