@import '../../assets/global.scss';
@import '../../assets/variables.scss';
@import '../../assets/mixins.scss';

.videoBox{@include sec-default();max-height:600px;overflow:hidden;margin-bottom:40px;position:relative;z-index:0;
  .videoWrap{border:none;}
  .vidStill{display:none;}

  .vidOverlay{position:absolute;width:100%;height:100%;left:0;top:0; background-color:rgba($black,.2);
    &.flex{display:flex;align-items:center;}
  }
  .vidText.showText{max-width:350px;
    &.left{margin-left:40px;}
  }
  .vidText.hide{display:none;}

  p{color:$white;font-weight:bold;font-size:27px;text-shadow:0px 1px 2px rgba($black,.5);font-family:$heading;line-height:1.4em;
    a{color:lighten($color2,15%);}
  }

  &.exteriorIntro{
    video{width:100%;}
    .videoWrap{margin-top:-120px;}
    .vidText.showText{position:relative;z-index:2;}
    .vidText.showText::before{@include before();background:radial-gradient(rgba($black,.5) 30%, transparent 70%);width:115%;left:-20%;top:-7%;}

    p{font-size:25px;}
    p a{color:$color1;}
  }

  &.marineIntroB{max-height:650px;
    video{width:100%;}
    .vidOverlay{background-color:transparent;}
  }

  &.siteIntro{max-height:900px;margin-bottom:0;  min-height:600px;@include sec-bgimage('./images/homeVideoShot1.png');background-size:cover;
    .vidOverlay{background-color:transparent;}
    .vidText.left{max-width:500px;margin-left:3%;margin-top:50px;position:relative;z-index:2;}
    .vidText::before{@include before();width:120%;height:130%;top:-5%;left:-15%;background:radial-gradient(rgba($black,.7) 10%, transparent 70%);}

    h1,h3,p{font-family:$heading;text-shadow:0px 1px 2px rgba($black,.4);}
    h1,p{color:$white;}
    h2,h1 span{text-transform:uppercase;margin-bottom:2px;font-weight:500;}
    h2{font-size:21px;letter-spacing:1px;font-weight:bold;color:$color1;}
    h2 span{background-color:$white;padding:0 5px;}
    h2 br{display:none;}
    h1{font-size:36px;font-weight:bold;line-height:1.4em;position:relative;z-index:0;}
    h1 span{line-height:1em;font-size:22.5px;letter-spacing:7px;}
    h3,a{color:$color1;font-size:25px;letter-spacing:1px;text-transform:capitalize;font-weight:bold; color:#e59660;}
  }

  @media (min-width:1921px){
    &.siteIntro{background-color:$color2;background-image:none;
      video{float:right;}
    }
  }

  @media (min-width:1025px){
    &.exteriorIntro .vidText.showText{max-width:460px;}
  }

  @include bp(tw){
    &.siteIntro{min-height:500px;
      .vidText::before{left:-20%;}
      .vidText.left{max-width:430px;margin-top:100px;}

      h1{font-size:33px;}
      h2{font-size:20px;}
      h3,a{font-size:22px;}
    }

    &.aboutIntro p a{color:$white;}
  }

  @include bp(tb){margin-bottom:30px;
    .vidText.showText.left{margin-left:30px;}
    p{font-size:24px;}


    &.siteIntro{min-height:430px;
      .vidOverlay{background-color:rgba($black, .1);}
      .vidText::before{left:-20%;}
      .vidText.left{max-width:360px;}

      h1{font-size:27px;}
      // h1::before{max-width:350px;}
      h1 span,h2,h3,a{font-size:18px;}
      h2{color:#e59660;}
    }

    &.exteriorIntro{
      .vidText.showText{max-width:410px;}
      p{font-size:22px;}
    }
  }
  @include bp(br){margin-bottom:20px;
    .vidText.showText{max-width:300px;}
    p{font-size:22px;}

    &.exteriorIntro{
      .vidText.showText{max-width:380px;}
      p{font-size:20px;}
    }
    &.exteriorIntro .vidText.showText::before{background:radial-gradient(rgba($black,.6) 30%, transparent 70%);width:130%;left:-25%;top:-8%;}
  }

  @include bp(nn){
    &.siteIntro{
      .vidOverlay{background-color:rgba($black, .3);}
      .vidText.left{max-width:600px;@include center-block();margin-top:60px;text-align:center;}

      h1{font-size:27px;}
      h1::before{max-width:100%;background-size:100px;}
    }
  }

  @include bp(og){
    &.ogSwitch{max-height:100%;
      .fullVideo{display:none;}
      .vidStill{@include sec-default();}

      .vidOverlay{background-color:rgba($black,.4);padding:20px;text-align:center;}
      .vidText.showText{@include sec-default();max-width:100%;
        &.left{margin-left:0;}
      }
      p{font-size:20px;margin-bottom:10px;}
    }

    &.siteIntro{height:100%;min-height:100%;
      .vidOverlay{background-color:rgba($black, .55);}
      .vidText::before{display:none;}
      .vidText.left{max-width:300px;margin-top:40px;text-align:left;}

      h1{font-size:22px;margin-bottom:10px;}
      h1::before{display:none;}
      h1 span,h2,h3,a{font-size:17px;}
      h1 span{letter-spacing:1px;}
      h2{text-shadow:0px 1px 2px rgba($black,.5);}
      h2 br{display:block;}
      h2 span{background-color:transparent;color:$white;padding:0;}
      h2,h3,a{font-weight:bold;letter-spacing:.3px;color:$color1;}
    }

    &.exteriorIntro{
      p{font-size:18px;max-width:365px;@include center-block();line-height:1.6em;}
      p a{color:$white;font-size:19px;}
    }
    &.exteriorIntro .vidStill{margin-top:-60px;}

    &.marineIntroB{height:100%;}

    &.aboutIntro p{line-height:1.3em;}

    p:last-child{margin-bottom:0;}
  }

  @include bp(oc){
    &.ogSwitch p{font-size:17px;}

    &.siteIntro{height:100%;
      .vidText.left{max-width:450px;text-align:center;margin-left:auto !important;}

      h1{font-size:21px;}
      h2 br{display:none;}
      h1 span, h2, h3, a{font-size:16px;}
    }

    &.exteriorIntro{height:100%;
      .vidStill{margin-top:0px;}
    }
  }

  @include bp(fi){
    &.siteIntro{
      .vidOverlay{position:relative;background-color:rgba($black, .6);}
      h1{font-size:20px;}
      .vidStill{display:none;}
      .vidText.left{margin-top:15px;}
    }

    &.exteriorIntro{
      .videoWrap{margin-top:-75px;}
      p a{font-size:18px;}
    }
  }
  @media (max-width:440px){&.exteriorIntro .videoWrap{margin-top:0px;}}
}